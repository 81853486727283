





























import AmountChooser from './amount-chooser'

export default
  components:
    'AmountChooser': AmountChooser


  created: ->
    if this.$root.preselectCategory == this.$parent.category.id
      this.onAmountChanged(1)


  data: ->
    newAmount: this.amount
    roomTypeName: this.$parent.category.room_type_name
    roomTypeNameText: this.$parent.category.room_type_name_text


  methods:
    onAmountChanged: (val) ->
      this.newAmount = val
      this.$parent.amount = val
      this.$root.preselectCategory = false if val == 0


  name: 'RoomChooser'


  props:
    amount: type: Number
    maxamount: type: Number
