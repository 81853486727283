











import BookingOptions from './booking-options'

export default
  components:
    'BookingOptions': BookingOptions


  name: 'BookingOptionCategories'


  props:
    bookingoptioncategories: type: Array
    loadedCategory: default: false
    
