






























































export default
  computed:
    formattedDescription: ->
        this.arrangement.description.replace(/\n/g, '<br>')

    fromTotalPrice: ->
      if this.$root.lowestRatesForBookingoptions[this.arrangement.id]
        this.price + this.$root.lowestRatesForBookingoptions[this.arrangement.id]

    price: ->
      if this.arrangement.price_per_night
        if this.arrangement.on_day_of_arrival && this.arrangement.on_day_of_departure
          return this.arrangement.price_per_night * (this.$root.durationOfStay + 1)
        else if this.arrangement.on_day_of_arrival || this.arrangement.on_day_of_departure
          return this.arrangement.price_per_night * this.$root.durationOfStay
        else
          return this.arrangement.price_per_night * (this.$root.durationOfStay - 1)
      else
        return this.arrangement.one_time_price


  methods:
    deselectArrangement: ->
      this.$root.selectedArrangement = 0
      this.$root.selectedArrangementObject = null
      this.$root.selectedArrangementPrice = 0

    selectArrangement: ->
      this.$root.selectedArrangement = this.arrangement.id
      this.$root.selectedArrangementObject = this.arrangement
      this.$root.selectedArrangementPrice = this.price


  name: 'Arrangement'


  props:
    arrangement: type: Object
