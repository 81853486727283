





































































import AmountChooser from './amount-chooser'
import Gallery from './gallery'
import truncate from 'vue-truncate-collapsed'


export default
  components:
    'AmountChooser': AmountChooser
    'Gallery': Gallery
    'truncate': truncate


  computed:
    bookingOptionActivatedInPolicy: ->
      bookingOptionId = this.bookingoption.id
      this.parentByName(this, 'Category').selectedRate['booking-options-policies'].filter((policy) -> policy['booking_option_id'] == bookingOptionId)[0]

    categoryGuestSum: ->
      this.parentByName(this, 'Category').guestNum

    categoryRatePrice: ->
      this.parentByName(this, 'Category').price

    categoryRoomingsAmount: ->
      this.parentByName(this, 'Category').amount

    policyAmount: ->
      if this.bookingOptionActivatedInPolicy?.id
        if this.bookingoption.per_person
          # this.categoryGuestSum
          1
        else
          this.categoryRoomingsAmount
      else
        this.amount

    policyPriceDetails: ->
      if policyIncluded = this.bookingOptionActivatedInPolicy?.included_in_lodging
        this.$t('booking.booking_option_included_in_lodging')
      else
        this.bookingoption.price_details

    policyPricePerNight: ->
      if policyPrice = this.bookingOptionActivatedInPolicy?.price_per_night
        policyPrice
      else
        this.pricePerNight

    policyIncludedInLodging: ->
      if policyIncluded = this.bookingOptionActivatedInPolicy?.included_in_lodging
        policyIncluded
      else
        this.bookingoption.included_in_lodging

    policyMinBookable: ->
      if this.bookingOptionActivatedInPolicy?.id
        if this.bookingoption.per_person
          # this.categoryGuestSum
          1
        else
          this.categoryRoomingsAmount
      else
        this.bookingoption.min_bookable

    price: ->
      # If this category is booked
      if this.parentByName(this, 'Category').amount > 0 && !this.policyIncludedInLodging
        total = 0
        # one time
        if this.oneTimePriceType == '%'
          total += this.categoryRatePrice / 100 * this.oneTimePrice
        else
          total += this.oneTimePrice

        # per night
        nights = this.$root.durationOfStay - 1
        nights += 1 if this.bookingoption.plus_arrival_day
        nights += 1 if this.bookingoption.plus_departure_day

        if this.pricePerNightType == '%'
          # Percentage of the room price, not multiplied by the number of nights
          total += this.categoryRatePrice / 100 * this.policyPricePerNight
        else
          # Fixed price per night, multiplied by the number of nights
          total += this.policyPricePerNight * nights

        # per person
        if this.perPerson
          return total * this.categoryGuestSum
        else
          return total
      else
        0

    total: ->
      this.amount * this.price


  created: ->
    if this.changesGuestNum
      this.parentByName(this, 'Category').hasOccupancyChangingbookingOption = true

    if this.policyAmount > 0
      this.amount = this.policyAmount


  data: ->
    amount: if this.loadedOption then Number(this.loadedOption.amount) else this.bookingoption.min_bookable
    changesGuestNum: this.bookingoption.increases_guest_num || -this.bookingoption.decreases_guest_num
    oneTimePrice: this.bookingoption.one_time_price
    oneTimePriceType: this.bookingoption.one_time_price_type
    perPerson: this.bookingoption.per_person
    pricePerNight: this.bookingoption.price_per_night
    pricePerNightType: this.bookingoption.price_per_night_type
    openCollapsed: false


  # destroyed: ->
  #   this.$parent.calcGuestNum()


  methods:
    onAmountChanged: (val) ->
      this.amount = val
      this.parentByName(this, 'Category').calcGuestNum()
      
    toggleCollapse: ->
      if this.openCollapsed == false
        this.openCollapsed = true
      else
        this.openCollapsed = false


  mounted: ->
    Event.$emit('amountChanged')

    # If this arrangement is already selected, activate it.
    if this.$root.selectedArrangement == this.bookingoption.id
      this.amount = 1


  name: 'BookingOption'


  props:
    bookingoption: type: Object
    loadedOption: default: false
    media: type: Array


  watch:
    amount: ->
      Event.$emit('amountChanged')
    price: ->
      Event.$emit('amountChanged')
