









import Arrangement from './arrangement'


export default
  components:
    'Arrangement': Arrangement


  name: 'Arrangements'


  props:
    arrangements: type: Array

