




export default
  data: ->
    isActive: false

  mounted: ->
    this.isActive = this.selected

  name: 'Tab'

  props:
    icon: default: false
    name: required: true
    selected: default: false
