















































































































































import BookingOptionCategories from './booking-option-categories'
import BookingOptions from './booking-options'
import Deals from './deals'
import Gallery from './gallery'
import Rate from './rate'
import RoomChooser from './room-chooser'
import Rooming from './rooming'

export default
  components:
    'BookingOptionCategories': BookingOptionCategories
    'BookingOptions': BookingOptions
    'Deals': Deals
    'Gallery': Gallery
    'Rate': Rate
    'RoomChooser': RoomChooser
    'Rooming': Rooming


  computed:
    filteredNonPackages: ->
      this.$options.filters.nonPackages(this.bookingoptions)

    filteredPackages: ->
      this.$options.filters.packages(this.bookingoptions)

    guestNum: ->
      this.roomingGuestNums.reduce(((pv, cv) ->
        pv + cv
      ), 0)

    maxAmount: ->
      if this.category.availability.closed
        return 0
      else if this.$root.selectedArrangement != 0 && this.bookableLimit > 0
        return 1
      else
        this.bookableLimit

    numberOfItems: ->
      if this.$root.ibeShowPhotosAndTitleOnly && this.$root.showSingleCategory.length
        2
      else
        1

    occupancyFit: ->
      !this.$root.guestNumFilter.length || this.$root.guestNumFilter <= this.category.occupancy[1]

    showBookingOptions: ->
      (
        (this.bookingoptioncategories && this.bookingoptioncategories.length) ||
        (this.bookingoptions && this.bookingoptions.length)
      ) &&
      this.amount > 0

    showGalleryControls: ->
      !this.$root.ibeShowPhotosAndTitleOnly || this.$root.showSingleCategory

    showRoomings: ->
      if this.hasOccupancyChangingbookingOption then true else false

    showSingle: ->
      (
        !this.$root.ibeHideOtherCategories.length || this.rootCategoriesArray.map((c) -> c.id.toString()).includes(this.category.id.toString())
      ) && (
        !this.$root.showSingleCategory.length || this.$root.showSingleCategory.toString() == this.category.id.toString()
      )


  data: ->
    amount: if this.loadedCategory then Number(this.loadedCategory.amount) else 0
    amenitiesLimit: 6
    bookableLimit: if !!this.category.booking_limit then Math.min(this.category.availability.available, this.category.booking_limit) else this.category.availability.available
    hasOccupancyChangingbookingOption: false
    hideByFilter: false
    hideByOthers: false
    isRateSelected: false
    price: 0
    roomingGuestNums: []
    selectedRate: false
    showConditions: this.$parent.showConditions
    showGallery: true


  filters:
    nonPackages: (array) ->
      return array.filter (object) ->
        !object.package

    packages: (array) ->
      return array.filter (object) ->
        object.package


  methods:
    calcGuestNum: ->
      $this = this
      this.roomingGuestNums = []

      # Set the correct guest nums for all roomings
      i = 0
      while i < this.amount
        this.roomingGuestNums[i] = this.category.max_guest_num
        i += 1

      # Loop through all booking options within "this"
      bookingOptions = this.findByName(this, 'BookingOption')

      bookingOptions.forEach (option) ->
        if option.changesGuestNum != 0
          amountPerRooming = Math.ceil(option.amount / $this.amount)
          amountLeft = option.amount

          # Loop through all roomings and share the amount equaly
          # until there is no amount left.
          i = 0
          while i < $this.amount
            thisAmount = if amountLeft > amountPerRooming then amountPerRooming else amountLeft
            $this.roomingGuestNums[i] += thisAmount * option.changesGuestNum
            amountLeft -= thisAmount
            i += 1

    setOccupancyBookingOption: ->
      if this.$root.guestNumFilter && (this.$root.showSingleCategory == this.category.id.toString()) && this.amount == 1
        shouldGestNum = this.$root.guestNumFilter
        isGuestNum    = this.guestNum
        bookingOptions = this.findByName(this, 'BookingOption')
        bookingOptions.forEach (option) ->
          if option.changesGuestNum > 0
            newAmount = (shouldGestNum - isGuestNum) / option.changesGuestNum
            if newAmount >= option.bookingoption.min_bookable && newAmount <= option.bookingoption.max_bookable
              option.amount = newAmount

    showAllAmenities: ->
      this.amenitiesLimit = 1000

    showSingleCategory: ->
      if this.$root.ibeShowPhotosAndTitleOnly && !this.$root.showSingleCategory.length
        url = './new?category_id=' + this.category.hash_id + '&date_from=' + this.$root.checkin + '&date_to=' + this.$root.checkout + '&guests=' + this.$root.guestNumFilter
        url += '&referrer=' + this.$root.referrer if this.$root.referrer
        url += '&code=' + this.$root.voucherCodeCampaign if this.$root.voucherCodeCampaign
        window.location.href = url


  mounted: ->
    this.calcGuestNum()


  name: 'Category'


  props:
    bookingoptioncategories: type: Array
    bookingoptions: type: Array
    category: type: Object
    deals: type: Array
    amenities: type: Array
    loadedCategory: default: false
    media: type: Array
    rates: type: Array


  watch:
    amount: (val, oldVal) ->
      this.$root.deselectOtherCategories(this) if this.$root.selectedArrangement != 0 && val == 1
      Event.$emit('amountChanged')
      this.calcGuestNum()

    price: (val, oldVal) ->
      Event.$emit('priceChanged')
