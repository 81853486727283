






























export default
  computed:
    isDecreaseClickable: ->
      this.newamount > this.minamount
    isIncreaseClickable: ->
      this.newamount < this.totalMaxAmount
    totalMaxAmount: ->
      # This maxamount times the category's amount for booking options
      if this.$parent.$options.name == 'BookingOption' && !this.perperson
        return this.maxamount * this.parentByName(this, 'Category').amount
      else
        return this.maxamount

  data: ->
    newamount: this.amount

  methods:
    decrease: ->
      this.newamount-- if this.newamount > this.minamount
    increase: ->
      this.newamount++ if this.newamount < this.totalMaxAmount

  props:
    amount:
      default: 0
    maxamount:
      default: 999
    minamount:
      default: 0
    perperson:
      default: false

  name: 'AmountChooser'

  watch:
    amount: (val, oldVal) ->
      this.newamount = val
    newamount: (val, oldVal) ->
      this.$emit('amountChangedTo', val)
      Event.$emit('amountChanged')
    totalMaxAmount:(val, oldVal) ->
      this.newamount = val if this.newamount > val
