



















import DateRangePicker from 'vue2-daterange-picker'
# import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default
  components:
    'date-range-picker': DateRangePicker

  computed:
    durationOfStay: ->
      this.$moment(this.checkout).diff(this.$moment(this.checkin), 'days')

  data: ->
    dateRange: { startDate: this.checkin, endDate: this.checkout }

  methods:
    checkOpen: ->
      # console.log 'toggle'
      # if picker = document.querySelector('.vue-daterange-picker')
      #   picker.scrollIntoView
      #     behavior: 'smooth'
      #     block: 'start'

    formatTimespanByPicker: (picker) ->
      start = this.$moment(picker.startDate).locale(window.locale)
      end   = this.$moment(picker.endDate).locale(window.locale)

      if picker.singleDatePicker
        if window.locale == 'en'
          if picker.timePicker
            return start.format('MMMM D, YYYY HH:mm')
          else
            return start.format('MMMM D, YYYY')
        else
          if picker.timePicker
            return start.format('D. MMMM YYYY HH:mm')
          else
            return start.format('D. MMMM YYYY')

      else
        if window.locale == 'en'
          if start.format('YYYY') == end.format('YYYY') && start.format('MM') == end.format('MM') && start.format('DD') == end.format('DD')
            return start.format('MMMM D, YYYY')
          else if start.format('YYYY') == end.format('YYYY') && start.format('MM') == end.format('MM')
            return(start.format('MMMM D') + ' — ' + end.format(' D, YYYY'))
          else if start.format('YYYY') == end.format('YYYY')
            return(start.format('MMMM D') + ' — ' + end.format('MMMM D, YYYY'))
          else
            return(start.format('MMMM D, YYYY') + ' — ' + end.format('MMMM D, YYYY'))

        else
          if start.format('YYYY') == end.format('YYYY') && start.format('MM') == end.format('MM') && start.format('DD') == end.format('DD')
            return start.format('D. MMMM YYYY')
          else if start.format('YYYY') == end.format('YYYY') && start.format('MM') == end.format('MM')
            return(start.format('D.') + ' — ' + end.format('D. MMMM YYYY'))
          else if start.format('YYYY') == end.format('YYYY')
            return(start.format('D. MMMM') + ' — ' + end.format('D. MMMM YYYY'))
          else
            return(start.format('D. MMMM YYYY') + ' — ' + end.format('D. MMMM YYYY'))

    mounted: ->
      this.mindate = new Date()

    updateValues: ->
      this.$root.lowestRatesForBookingoptions = {}

      checkin  = this.dateRange.startDate.toJSON().slice(0, 10)
      checkout = this.dateRange.endDate.toJSON().slice(0, 10)

      # If checkin and checkout are the same date,
      # add one day to the checkout.
      if checkin == checkout
        checkout = this.$moment(checkout).add(1, 'day').format('YYYY-MM-DD')

      # Reload categories data
      this.$root.checkin  = checkin
      this.$root.checkout = checkout

  name: 'Date'

  props:
    autoapply: default: true
    checkin: type: String
    checkout: type: String
    helptext: default: false
    mindate: type: Date
    ranges: default: false

  watch:
    checkin: ->
      this.dateRange = { startDate: this.checkin, endDate: this.checkout }
    checkout: ->
      this.dateRange = { startDate: this.checkin, endDate: this.checkout }
