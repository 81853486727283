






































































export default
  computed:
    price: ->
      $this = this

      # Only bookable rates
      if this.minstay <= this.$root.durationOfStay && (this.maxstay == 0 || this.maxstay >= this.$root.durationOfStay)
        # Set the lowest rate for booking options
        if this.$parent.bookingoptions
          this.$parent.bookingoptions.forEach (bookingoption) ->
            if !$this.$root.lowestRatesForBookingoptions[bookingoption.id] || $this.rate.price < $this.$root.lowestRatesForBookingoptions[bookingoption.id]
              $this.$root.lowestRatesForBookingoptions[bookingoption.id] = $this.rate.price

      selectedArrangementPrice = this.$root.selectedArrangementPrice
      if this.$root.selectedArrangementObject?.per_person
        if this.$parent.guestNum.length
          selectedArrangementPrice *= this.$parent.guestNum
        else
          selectedArrangementPrice *= this.$parent.category.max_guest_num

      return this.rate.price + selectedArrangementPrice


  created: ->
    # Select the first possible rate for this category
    if !this.$parent.isRateSelected && (this.maxstay == 0 || this.$root.durationOfStay <= this.maxstay) && this.$root.durationOfStay >= this.minstay
      this.selected = true
      this.$parent.isRateSelected = true
      this.$parent.selectedRate = this.rate
      this.$parent.price = this.rate.price


  data: ->
    customText: this.rate['custom-text']
    maxstay: this.rate['max-stay']
    minstay: this.rate['min-stay']
    originalPrice: this.rate['original-price']
    selected: false
    showConditions: this.$parent.showConditions


  methods:
    select: (event) ->
      this.$parent.$children.forEach (element) ->
        element.selected = false
      this.selected = true
      this.$parent.selectedRate = this.rate
      this.$parent.price = this.price
      this.$root.updateTotals()


  name: 'Rate'


  props:
    rate: type: Object
    rateCount: type: Number
