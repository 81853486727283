
















































































































export default


  methods:
    scrollToGuestData: ->
      if guestdata = document.querySelector('#guestdata')
        guestdata.scrollIntoView
          behavior: 'smooth'


  name: 'Bookingsummary'


  props:
    bookingoptions: default: []
    categories: default: []
    citytax: Boolean
    currency: type: String
    loadedtype: default: false
    total: type: Number
    types: type: Array
