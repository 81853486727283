export default
  methods:
    # Find all direct children by name
    childrenByName: (object, componentName) ->
      if object
        return object.$children.filter( (component) ->
          component.$options.name == componentName
        )

    # Find all nested elements by name
    findByName: (object, componentName) ->
      components = []

      # TODO: Make recursive
      if object.$children.length
        object.$children.forEach (child) ->
          components.push(child) if child.$options.name == componentName

          if child.$children.length
            child.$children.forEach (child) ->
              components.push(child) if child.$options.name == componentName

              if child.$children.length
                child.$children.forEach (child) ->
                  components.push(child) if child.$options.name == componentName

                  if child.$children.length
                    child.$children.forEach (child) ->
                      components.push(child) if child.$options.name == componentName
                        
                      if child.$children.length
                        child.$children.forEach (child) ->
                          components.push(child) if child.$options.name == componentName

                          if child.$children.length
                            child.$children.forEach (child) ->
                              components.push(child) if child.$options.name == componentName

                              if child.$children.length
                                child.$children.forEach (child) ->
                                  components.push(child) if child.$options.name == componentName

      return components


    # Find the closest parent by name
    parentByName: (object, componentName) ->
      parent = object.$parent
      while (parent && !component)
        if (parent.$options.name == componentName)
          component = parent

        parent = parent.$parent
      return component
