

































































export default
  computed:
    dateEnd: ->
      this.$moment(this.checkout).add(1, 'month')
    dateStart: ->
      start = this.$moment(this.checkin).subtract(1, 'month')
      start = this.$moment() if start.format('YYYY-MM-DD') < this.$moment().format('YYYY-MM-DD')
      return start
    dateRange: ->
      days = []
      day = this.dateStart
      while (day <= this.dateEnd)
        days.push day
        day = day.clone().add(1, 'day')
      return days

  created: ->
    this.show = this.immediately == 1

    # Load content
    this.loadRates()


  data: ->
    loading: false
    markedCategory: false
    markedEnd: false
    markedStart: false
    nextClicked: 0
    rates: {}
    show: false
    clickTimeout: false
    today: this.$moment()


  methods:
    loadRates: ->
      $this = this
      this.loading = true

      # Load the rates
      this.$http.get($this.baseurl,
        params:
          checkin: this.$root.checkin
          checkout: this.$root.checkout
          date: this.dateStart.format('YYYY-MM-DD')
          date_to: this.dateEnd.format('YYYY-MM-DD')
          show: 'lowest'
      ).then((response) ->
        $this.error = false
        $this.loading = false
        if response.data
          $this.rates = response.data

          # Scroll calendar to checkin date (after 200ms)
          setTimeout ->
            nameWidth = Math.ceil(document.querySelector('#reservation-calendar tbody td.body-name').offsetWidth)
            selectedOffsetLeft = document.querySelector('#reservation-calendar th.day-selected').offsetLeft

            calendarWrapper = document.querySelector('#reservation-calendar-wrapper')
            
            # Check if scrollTo method exists, Edge doesn't support it.
            if typeof calendarWrapper.scrollTo == 'function' 
              calendarWrapper.scrollTo
                behavior: 'smooth'
                left: selectedOffsetLeft - nameWidth
          , 200
        else
          $this.error = true
      ).catch((error) ->
        $this.loading = false
        $this.error = true
      )

    markDay: (day, category) ->
      if typeof(category) == 'object'
        this.markedCategory = category.id
      else
        this.markedCategory = category

      if !this.markedStart
        this.markedEnd = false
        this.markedStart = day
        this.startClickTimeout()

      else if this.markedStart && !this.markedEnd
        if day.format('YYYY-MM-DD') < this.markedStart.format('YYYY-MM-DD')
          this.markedStart = day
          this.startClickTimeout()
        else
          this.stopClickTimeout()

          # If start and end are the same date,
          # increase the end date by one day.
          if day.format('YYYY-MM-DD') == this.markedStart.format('YYYY-MM-DD')
            min_stay = this.rates[day.format('YYYY-MM-DD')][this.markedCategory][0]['min_stay'] || 1
            this.markedEnd = day.clone().add(min_stay, 'day')
          else
            this.markedEnd = day

          # Set new checkin and checkout dates
          this.$root.checkin = this.markedStart.format('YYYY-MM-DD')
          this.$root.checkout = this.markedEnd.format('YYYY-MM-DD')
          # this.$root.preselectCategory = this.markedCategory
          # Reset marked days
          this.markedCategory = false
          this.markedEnd = false
          this.markedStart = false

    # Load the next dates
    next: ->
      this.$root.checkin = this.$moment(this.$root.checkin).add(1, 'month').format('YYYY-MM-DD')
      this.$root.checkout = this.$moment(this.$root.checkout).add(1, 'month').format('YYYY-MM-DD')
      this.nextClicked++

    # Load the previous dates
    prev: ->
      this.$root.checkin = this.$moment(this.$root.checkin).subtract(1, 'month').format('YYYY-MM-DD')
      this.$root.checkout = this.$moment(this.$root.checkout).subtract(1, 'month').format('YYYY-MM-DD')
      this.nextClicked--

    # One click timeout
    startClickTimeout: ->
      $this = this
      this.clickTimeout = setTimeout ->
        $this.markDay($this.markedStart, $this.markedCategory)
      , 4000

    stopClickTimeout: ->
      clearTimeout(this.clickTimeout)


  name: 'ReservationCalendar'


  props:
    baseurl: type: String
    categories: type: Array
    checkin: type: String
    checkout: type: String
    immediately: type: Number
    sale: type: Boolean
    showconditions: type: Boolean


  watch:
    checkin: ->
      this.loadRates() if !this.loading
    checkout: ->
      this.loadRates() if !this.loading
