


























import Category from './category'


export default
  components:
    'Category': Category


  computed:
    atLeastOneBookable: ->
      bookable = false
      guestNumFilter = this.$root.guestNumFilter
      this.categories.forEach (category) ->
        bookable = true if category.self.availability.bookable && guestNumFilter <= category.self.occupancy[1]
      return bookable


  created: ->
    this.loadCategories()


  data: ->
    categories: []
    loadedCategories: if this.$root.loadedQueryString then this.$root.loadedQueryString.categories else false
    loading: true
    showConditions: this.showconditions


  methods:
    loadCategories: ->
      $this = this
      $this.loading = true

      # Load the categories' data
      this.$http.get($this.baseurl,
        params:
          checkin: $this.checkin
          checkout: $this.checkout
      ).then((response) ->
        $this.error = false
        $this.loading = false
        if response.data
          $this.categories = response.data
          Event.$emit('amountChanged')
        else
          $this.error = true
      ).catch((error) ->
        $this.loading = false
        $this.error = true
      )


  name: 'Categories'


  props:
    baseurl: type: String
    checkin: type: String
    checkout: type: String
    fulltext: type: String
    showconditions: type: Boolean


  watch:
    checkin: ->
      this.loadCategories() if !this.loading
    checkout: ->
      this.loadCategories() if !this.loading
