






import BookingOption from './booking-option'

export default
  components:
    'BookingOption': BookingOption


  mounted: ->
    this.parentByName(this, 'Category').setOccupancyBookingOption()


  name: 'BookingOptions'


  props:
    bookingoptions: type: Array
    loadedCategory: default: false
    title: type: String
    
