







export default
  computed:
    categoryAmount: ->
      this.$parent.amount

  created: ->
    Event.$emit('amountChanged')

  data: ->
    roomTypeName: this.$parent.category.room_type_name_text

  name: 'Rooming'

  props:
    guestNum: type: Number
    i: type: Number
    show: type: Boolean
