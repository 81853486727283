














export default
  data: ->
    showFilters: false


  name: 'AmenityFilters'


  props:
    filters: type: Array
