# Babel
import '@babel/polyfill'
import 'core-js'

# Vue
import Vue from 'vue/dist/vue.common' # common uses "vue" in development "vue.min" in production
import VueI18n from 'vue-i18n'
import Axios from 'axios'
import moment from 'moment'
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light-border.css'

# Components & Mixins
import AmenityFilters from './components/amenity-filters'
import Arrangements from './components/arrangements'
import Bookingsummary from './components/booking-summary'
import Categories from './components/categories'
import Date from './components/date'
import Gallery from './components/gallery'
import GeneralHelper from './helpers/general-helper'
import Prepayment from './components/prepayment'
import ReservationCalendar from './components/reservation-calendar'
import Voucher from './components/voucher'

document.addEventListener 'DOMContentLoaded', ->

  window.reservationForm = document.querySelector('#reservation-form-vue');

  window.Event = new Vue

  window.messages = 
    de:
      booking:
        amenities: 'Ausstattung'
        arrangement_selected: 'Arrangement ausgewählt'
        arrangements: 'Arrangements'
        an_error_occurred: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'
        book_arrangement: 'Arrangement auswählen'
        book_room: '{roomName} auswählen'
        booked_out: 'Bereits ausgebucht'
        booked_room: '{roomName} ausgewählt'
        booking_option_included_in_lodging: 'im Logis-Preis inbegriffen'
        booking_options: 'Buchungsoptionen'
        booking_options_min_stay: 'Diese Option steht erst ab einem Aufenthalt von {num} Nächten zur Verfügung.'
        booking_options_not_bookable_for_this_daterange: 'Im gewählten Zeitraum steht die Option leider nicht zur Verfügung.'
        booking_options_together: 'Buchungsoptionen für alle Zimmer zusammen'
        check: 'Prüfen'
        choose_guest_num_info: 'Wählen Sie die Anzahl der Gäste in den Buchungsoptionen.'
        click_to_remove: 'Zum Entfernen anklicken'
        click_to_select: 'Zum Buchen anklicken'
        daterange_help: '<b>Klicken</b> Sie zuerst auf das <b>Anreise</b>-Datum und dann auf das <b>Abreise</b>-Datum.'
        discount: 'Rabatt'
        for_x_guests: '{roomName} nicht verfügbar | {roomName} für 1 Gast | {roomName} für {n} Gäste'
        from: 'ab'
        inclusive: 'inklusive'
        max_stay: 'Maximale Aufenthaltsdauer'
        min_stay: 'Mindestaufenthalt'
        nightsTotal: '0 Nächte | 1 Nacht | {n} Nächte'
        no_vouchers_for_reduced_rates_alert: 'Gutscheine sind nicht auf reduzierte Preise anwendbar.'
        occupancy: 'Belegung'
        packages: 'Arrangements'
        packages_together: 'Arrangements für alle Zimmer zusammen'
        persons: '0 Personen | 1 Person | {n} Personen'
        room: 'Zimmer'
        select: 'Auswählen'
        select_room_for_arrangement: '{roomName} für Arrangement auswählen'
        selected: 'Gebucht'
        show_categories_with_extrabed: 'Nur mit Zustellbett anzeigen'
        show_more_amenities: 'Mehr anzeigen'
        truncate_clamp: '... Mehr anzeigen >'
        voucher_code: 'Gutschein-Code'
      categories:
        names_plural:
          apartment: 'Apartments'
          conferenceroom: 'Konferenzräume'
          functionroom: 'Veranstaltungsräume'
          meetingroom: 'Tagungsräume'
          multifunctionroom: 'Multifunktionsräume'
          room: 'Zimmer'
          suite: 'Suiten'
          vacation_apartment: 'Ferienwohnungen'
          vacation_home: 'Ferienhäuser'
      date:
        apply: 'Reisedaten speichern'
        cancel: 'Abbrechen'
        next: 'weiter'
        prev: 'zurück'
        show_calendar: 'Kalender anzeigen'
      payments:
        tabs:
          creditcard:
            content: 'Sie haben die <strong>Zahlung per Kreditkarte</strong> ausgewählt und werden nach Absenden der Reservierung automatisch umgeleitet, um die Zahlung vorzunehmen.'
            title: 'Kreditkarte'
          paypal:
            content: 'Sie haben <strong>PayPal</strong> als Zahlungsmethode ausgewählt und werden nach Absenden der Reservierung automatisch umgeleitet, um die Zahlung vorzunehmen.'
            title: 'PayPal'
          paypal_creditcard:
            content: 'Sie haben <strong>Zahlung per Kreditkarte</strong> (ohne PayPal-Konto) als Zahlungsmethode ausgewählt und werden nach Absenden der Reservierung automatisch umgeleitet, um die Zahlung vorzunehmen.'
            title: 'Kreditkarte'
          paypal_debit:
            content: 'Sie haben <strong>Zahlung per SEPA-Lastschrift</strong> (ohne PayPal-Konto) als Zahlungsmethode ausgewählt und werden nach Absenden der Reservierung automatisch umgeleitet, um die Zahlung vorzunehmen.'
            title: 'Lastschrift'
          sofort:
            content: 'Sie haben <strong>SOFORT-Überweisung</strong> als Zahlungsmethode ausgewählt und werden nach Absenden der Reservierung automatisch umgeleitet, um die Zahlung vorzunehmen.'
            title: 'Sofortüberweisung'
        title: 'Zahlung'
        totalText: '{total} {currency} sind im Voraus zu zahlen'
      rates:
        cancelable_at_any_time: 'Jederzeit kostenlos stornierbar'
        cancelable_free_of_charge: 'Kostenfrei stornierbar'
        cancelation_fee: 'Gebühr bei Stornierung'
        deselected: 'Rate auswählen'
        fee_for_non_free_cancelation: 'Gebühr bei nicht-kostenfreier Stornierung'
        max_stay_days_warning: 'Maximale Aufenthaltsdauer überschritten!'
        min_stay_days_warning: 'Mindestaufenthalt noch nicht erreicht!'
        nights_count: 'keine Nacht | 1 Nacht | {n} Nächte'
        no_rates_found: 'Für den gewählten Zeitraum kann diese Kategorie derzeit nicht gebucht werden. Bitte beachten Sie den Mindestaufenthalt.'
        prepay_fee: 'Im Voraus zu bezahlen'
        price_for_seleted_period_included: 'Preis für den gewählten Zeitraum, inkl. Steuern'
        selected: 'Rate ausgewählt'
      reservations:
        set_number: 'Anzahl einstellen'
        set_number_of_rooms: 'Anzahl der {roomNames} einstellen'
      summary:
        booking_options: 'Buchungsoptionen'
        checkin: 'Anreise'
        checkout: 'Abreise'
        citytax_info: 'Die Stadt- und Tourismusabgabe ist nicht im Preis enthalten und wird vor Ort erhoben.'
        contact_info_link: 'Auswahl komplett? Fortfahren mit Eingabe Ihrer Adresse.'
        incl_tax: 'inkl. Steuern'
        link_to_address_fields: 'Zur Adresseingabe'
        lodging: 'Logis'
        title: 'Zusammenfassung'
        to_be_paid_in_advance: 'Im Voraus zu zahlen'
        total: 'Gesamt'
        voucher: 'Gutschein'
      vouchers:
        not_valid: 'Der Gutschein konnte nicht eingelöst werden.'

    en:
      booking:
        amenities: 'Amenities'
        arrangements: 'Packages'
        arrangement_selected: 'Package selected'
        an_error_occurred: 'An error has occurred. Please try again.'
        book_arrangement: 'Book package'
        book_room: 'Select {roomName}'
        booked_out: 'Already booked'
        booked_room: '{roomName} selected'
        booking_option_included_in_lodging: 'included in lodging price'
        booking_options: 'Booking options'
        booking_options_min_stay: 'This option is only available for stays of at least {num} nights.'
        booking_options_not_bookable_for_this_daterange: 'The option is not available in the selected period.'
        booking_options_together: 'Booking options for all rooms together'
        check: 'Check'
        choose_guest_num_info: 'Select the number of guests in the booking options.'
        click_to_remove: 'Click to deselect'
        click_to_select: 'Click to select'
        daterange_help: 'First <b>click</b> on the <b>arrival</b> date and then on the <b>departure</b> date.'
        discount: 'discount'
        for_x_guests: '{roomName} not available | {roomName} for 1 guest | {roomName} for {n} guests'
        from: 'from'
        inclusive: 'inclusive'
        max_stay: 'Maximum staying duration'
        min_stay: 'Minimum stay'
        nightsTotal: '0 nights | 1 night | {n} nights'
        no_vouchers_for_reduced_rates_alert: 'Vouchers are not applicable to reduced prices.'
        occupancy: 'Occupancy'
        packages: 'Packages'
        packages_together: 'Packages for all rooms together'
        persons: '0 persons | 1 person | {n} persons'
        room: 'Room'
        select: 'Select'
        select_room_for_arrangement: 'Select {roomName} for package'
        selected: 'Selected'
        show_categories_with_extrabed: 'Show only with extra bed'
        show_more_amenities: 'Show more'
        truncate_clamp: '... Show more >'
        voucher_code: 'Voucher code'
      categories:
        names_plural:
          apartment: 'Apartments'
          conferenceroom: 'Conference rooms'
          functionroom: 'Function rooms'
          meetingroom: 'Meeting rooms'
          multifunctionroom: 'Multifunction areas'
          room: 'Rooms'
          suite: 'Suites'
          vacation_apartment: 'Vacation apartments'
          vacation_home: 'Vacation homes'
      date:
        apply: 'Save dates'
        cancel: 'Abort'
        next: 'next'
        prev: 'previous'
        show_calendar: 'Show calendar'
      payments:
        tabs:
          creditcard:
            content: 'You have selected <strong>Credit card payment</strong> and will be automatically redirected to make the payment after sending the reservation.'
            title: 'Credit Card'
          paypal:
            content: 'You have chosen <strong>PayPal</strong> as your payment method and will be automatically redirected to make the payment when you send the reservation.'
            title: 'PayPal'
          paypal_creditcard:
            content: 'You have selected <strong>Payment by credit card</strong> (without a PayPal account) as your payment method and will be automatically redirected to make the payment after submitting the reservation.'
            title: 'Credit Card'
          paypal_debit:
            content: 'You have selected <strong>Payment by SEPA Direct Debit</strong> (without a PayPal account) as your payment method and will be automatically redirected to make the payment after submitting the reservation.'
            title: 'Direct Debit'
          sofort:
            content: 'You have selected <strong>SOFORT-Überweisung</strong> as your payment method and will be automatically redirected to make the payment when you send the reservation.'
            title: 'Sofortüberweisung'
        title: 'Payment'
        totalText: '{total} {currency} are to be paid in advance'
      rates:
        cancelable_at_any_time: 'Cancel free anytime'
        cancelable_free_of_charge: 'Cancellable free of charge'
        cancelation_fee: 'Cancellation fee'
        deselected: 'Select rate'
        fee_for_non_free_cancelation: 'Fee for non-free cancellation'
        max_stay_days_warning: 'Maximum stay exceeded!'
        min_stay_days_warning: 'Minimum stay not reached!'
        nights_count: 'no night | one night | {n} nights'
        no_rates_found: 'This category cannot currently be booked for the selected period. Please note the minimum stay.'
        prepay_fee: 'To be paid in advance'
        price_for_seleted_period_included: 'Price for the selected dates, incl. taxes'
        selected: 'Rate selected'
      reservations:
        set_number: 'Set the number'
        set_number_of_rooms: 'Set the number of {roomNames}'
      summary:
        booking_options: 'Booking options'
        checkin: 'Arrival'
        checkout: 'Departure'
        contact_info_link: 'Room selection complete? Continue entering your contact information.'
        incl_tax: 'taxes included'
        link_to_address_fields: 'Add your address'
        lodging: 'Lodging'
        title: 'Summary'
        to_be_paid_in_advance: 'To pay in advance'
        total: 'Grand total'
        voucher: 'Voucher'
      vouchers:
        not_valid: 'The voucher could not be redeemed.'


  # Use I18n globally
  Vue.use(VueI18n)
  
  i18n = new VueI18n(
    locale: window.locale
    messages: messages)


  # Tippy Tooltips
  Vue.use(VueTippy)
  Vue.component('tippy', TippyComponent)

  # Define localized moment as $moment
  moment.locale(window.locale)
  Vue.prototype.$moment = moment

  # Define Axios as $http
  Vue.prototype.$http = Axios

  # Use global mixins
  Vue.mixin(GeneralHelper)


  ibe = new Vue
    components:
      'amenity-filters': AmenityFilters
      'Arrangements': Arrangements
      'Bookingsummary': Bookingsummary
      'Categories': Categories
      'Date': Date
      'Gallery': Gallery
      'Prepayment': Prepayment
      'reservation-calendar': ReservationCalendar
      'Voucher': Voucher

    computed:
      durationOfStay: ->
        moment(this.checkout).diff(moment(this.checkin), 'days')


    created: ->
      $this = this

      Event.$on 'amountChanged', ->
        $this.updateTotals()

      Event.$on 'priceChanged', ->
        $this.updateTotals()

      # Check if the submit button should be disabled
      this.toggleSubmitButton()

      # Set the referrer
      this.setReferrer()


    data:
      amenitiesFilters: []
      bookingoptionsArray: []
      categoriesArray: []
      checkin: reservationForm.getAttribute('data-checkin')
      checkout: reservationForm.getAttribute('data-checkout')
      filterExtrabedActive: false
      grandTotal: 0
      guestNumFilter: if document.querySelector('#reservation_ibe_guest_num_filter') then document.querySelector('#reservation_ibe_guest_num_filter').value else false
      ibeBookingOptionsCompact: reservationForm.getAttribute('data-ibe-booking-options-compact') == '1' ? true : false
      ibeCategoriesCompact: reservationForm.getAttribute('data-ibe-categories-compact') == '1' ? true : false
      ibeHideOtherCategories: reservationForm.getAttribute('data-ibe-hide-other-categories') == '1' ? true : false
      ibeShowPhotosAndTitleOnly: reservationForm.getAttribute('data-ibe-show-photo-and-title-only') == '1' ? true : false
      ibeShowSalePrices: reservationForm.getAttribute('data-ibe-show-sale-prices') == '1' ? true : false
      loadedQueryString: JSON.parse(reservationForm.getAttribute('data-reservation-query'))['query']
      lowestRatesForBookingoptions: {}
      media: JSON.parse(reservationForm.getAttribute('data-media'))
      optionsTotal: 0
      prepayTotal: 0
      prepayType: false
      preselectCategory: false
      reducedPriceSelected: false
      referrer: ''
      reservation: {}
      reservationQuery: ''
      requiredChecked: false
      roomsTotal: 0
      selectedArrangement: 0
      selectedArrangementObject: null
      selectedArrangementPrice: 0
      showSingleCategory: reservationForm.getAttribute('data-ibe-show-single-category-id')
      submitting: false
      taxesTotal: 0
      version: 1
      voucherCode: ''
      voucherCodeCampaign: reservationForm.getAttribute('data-voucher-code') ? ''
      voucherDiscountAmount: 0
      voucherDiscountType: false
      voucherTotal: 0


    el: '#reservation-form-vue'


    i18n: i18n


    methods:
      # Check if dateranges are in a dateranges.
      # If no daterange is given, always return true.
      daterangesContainDates: (dateranges, start, end) ->
        containing = false

        if dateranges.length
          dateranges.forEach (daterange) ->
            if moment(daterange.start) <= moment(start) && moment(daterange.end) >= moment(end)
              containing = true
              return false # to end the forEach loop

        # If no daterange contained the current dates,
        # return false.
        return containing

      deselectOtherCategories: (selectedCategory) ->
        $this = this

        # Loop through categories
        categoriesComponent = this.childrenByName(this, 'Categories')[0]
        this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
          if selectedCategory.category.id != category.category.id
            $this.childrenByName(category, 'RoomChooser').forEach (room_chooser) ->
              room_chooser.newAmount = 0

      filterExtrabed: ->
        # Deactivate filter & show all categories
        if this.filterExtrabedActive
          this.filterExtrabedActive = false
          categoriesComponent = this.childrenByName(this, 'Categories')[0]
          this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
            category.hideByFilter = false

        # Activate filter & hide certain categories
        else
          this.filterExtrabedActive = true
          categoriesComponent = this.childrenByName(this, 'Categories')[0]
          this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
            if !category.category.has_extrabed
              category.hideByFilter = true

      formatPrice: (value) ->
        if window.locale == 'de'
          (value / 1).toFixed(2).replace('.', ',')
        else
          (value / 1).toFixed(2)

      increaseNum: (numChanger) ->
        alert 1
        return

      setReferrer: ->
        queryString = window.location.search
        if queryString
          urlParams = new URLSearchParams(queryString)
          this.referrer = urlParams.get('referrer')

      setReservationQuery: ->
        array = []
        $this = this

        # Guest num filter
        array.push 'query[ibe_guest_num_filter]=' + this.guestNumFilter

        # Loop through categories
        categoriesComponent = this.childrenByName(this, 'Categories')[0]

        this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
          if category.amount > 0
            array.push 'query[categories][' + category.category.id + '][amount]=' + category.amount
            array.push 'query[categories][' + category.category.id + '][cancelation_fee_percent]=' + category.selectedRate['cancelation-fee-percent']
            array.push 'query[categories][' + category.category.id + '][cancelation_free_days]=' + category.selectedRate['cancelation-free-days']
            array.push 'query[categories][' + category.category.id + '][min_stay]=' + category.selectedRate['min-stay']
            array.push 'query[categories][' + category.category.id + '][prepay_fee_percent]=' + category.selectedRate['prepay-fee-percent']
            array.push 'query[categories][' + category.category.id + '][policy_id]=' + category.selectedRate['id']
            array.push 'query[categories][' + category.category.id + '][price]=' + category.selectedRate['price']

            # Loop through room(ing)s
            $this.findByName(category, 'Rooming').forEach (rooming, i) ->
              if rooming.guestNum > 0
                array.push 'query[categories][' + category.category.id + '][rooms][' + i + '][guest_num]=' + rooming.guestNum

            # Loop through booking options
            $this.findByName(category, 'BookingOption').forEach (option, i) ->
              if option.amount > 0
                array.push 'query[categories][' + category.category.id + '][booking_options][' + option.bookingoption.id + '][amount]=' + option.amount

        # Add prepay price and payment type
        if this.prepayTotal > 0
          array.push 'query[prepay_total]=' + this.prepayTotal
          array.push 'query[prepay_type]=' + this.prepayType

        # Custom IBE fields
        if (customIbeFields = document.querySelectorAll('input.custom-ibe-field')).length
          customIbeFields.forEach (field, i) ->
            array.push 'query[custom_ibe_fields][' + field.getAttribute('data-id') + ']=' + field.value

        # Voucher code
        if this.voucherCode?.length
          array.push 'query[voucher_code]=' + this.voucherCode
          array.push 'query[voucher_discount_amount]=' + this.voucherDiscountAmount
          array.push 'query[voucher_discount_type]=' + this.voucherDiscountType

        # Set the reservation query variable
        this.reservationQuery = array.join('&')

      setShowSingleCategory: ->
        if this.loadedQueryString && this.loadedQueryString.categories
          id = Object.keys(this.loadedQueryString.categories)[0]
          this.showSingleCategory = id

      showCalendar: ->
        calendarComponent = this.childrenByName(this, 'ReservationCalendar')[0]
        calendarComponent.show = true

      submitForm: ->
        this.submitting = true
        # Reactivate button after 3 seconds
        $this = this
        setTimeout ->
          $this.submitting = false
        , 3000


      # Check if all required checkboxes are checked,
      # otherwise disable the submit button
      toggleSubmitButton: ->
        checkboxes = document.querySelectorAll('.required-checkbox')

        if checkboxes.length == document.querySelectorAll('.required-checkbox:checked').length
          this.requiredChecked = true
        else
          this.requiredChecked = false


      # Loop through all categories and booking options
      # and calculate the sums and arrays
      updateTotals: ->
        $this = this

        bookingoptions = []
        categories = []
        reducedPriceSelected = false

        oTotal = 0
        pTotal = 0
        rTotal = 0

        categoriesComponent = this.childrenByName(this, 'Categories')[0]

        highestPrepayFeePercent = 0

        this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
          thisCategoryTotal = 0

          # Check if reduced price is selected
          if category.amount > 0 && (category.price < category.category.default_price || category.deals[0])
            reducedPriceSelected = true

          # Add to array
          if category.amount > 0
            if categories.find((c, i) ->
              if c['name'] == category.category.name
                categories[i]['amount'] += 1
                return true
              )
            else
              categories.push { amount: category.amount, id: category.category.id, name: category.category.name }

          rTotal += category.amount * category.price
          thisCategoryTotal += category.amount * category.price

          # Collect all booking options nested within category
          bookingOptions = $this.findByName(category, 'BookingOption')

          bookingOptions.forEach (option) ->
            # Add to array
            if option.amount > 0
              if bookingoptions.find((c, i) ->
                if c['name'] == option.bookingoption.name
                  bookingoptions[i]['amount'] += 1
                  return true
              )
              else
                bookingoptions.push { amount: option.amount, included: option.policyIncludedInLodging, name: option.bookingoption.name }

            oTotal += option.total
            thisCategoryTotal += option.total

          # Get highest prepay fee percent
          if category.selectedRate['prepay-fee-percent'] > highestPrepayFeePercent
            highestPrepayFeePercent = category.selectedRate['prepay-fee-percent']

        # Voucher
        if this.voucherDiscountAmount > 0
          if this.voucherDiscountType == '%25'
            this.voucherTotal = (this.roomsTotal + this.optionsTotal) / 100 * this.voucherDiscountAmount
          else
            this.voucherTotal = this.voucherDiscountAmount
        else
          this.voucherTotal = 0

        # Set data variables
        this.reducedPriceSelected = reducedPriceSelected

        this.bookingoptionsArray = bookingoptions
        this.categoriesArray = categories

        # Round to two decimals
        this.roomsTotal = Math.round(rTotal * 100) / 100
        this.optionsTotal = Math.round(oTotal * 100) / 100
        this.grandTotal = this.roomsTotal + this.optionsTotal - this.voucherTotal

        this.prepayTotal = Math.round(this.grandTotal * highestPrepayFeePercent) / 100
        this.prepayTotal = 0 if this.prepayTotal < 0

        this.setReservationQuery()


    mounted: ->
      this.setShowSingleCategory()


    watch:
      categoriesArray: ->
        if this.ibeHideOtherCategories && this.categoriesArray.length
          $this = this
          categoriesComponent = this.childrenByName(this, 'Categories')[0]
          this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
            if $this.categoriesArray.map((c) -> c.id).includes(category.category.id)
              category.hideByOthers = false
            else
              category.hideByOthers = true

      prepayType: ->
        this.setReservationQuery()

      selectedArrangement: (newId, oldId) ->
        $id = if newId != 0 then newId else oldId
        $this = this
        categoriesComponent = this.childrenByName(this, 'Categories')[0]

        # Loop through all categories and hide the ones
        # that don't provide the selected arrangement.  
        this.childrenByName(categoriesComponent, 'Category').forEach (category) ->
          hide = true
          category.bookingoptions.forEach (option) ->
            if newId == 0 || option.id == newId
              hide = false

          # Loop through all booking options COMPONENTS and set the
          # arrangement's amount.
          bookingOptions = $this.findByName(category, 'BookingOption')
          bookingOptions.forEach (option) ->
            if option.bookingoption.id == $id
              option.amount = if newId != 0 then 1 else 0

          if hide
            category.hideByFilter = true
          else
            category.hideByFilter = false

      showSingleCategory: (newId, oldId) ->
        # Scroll to top of page
        if !newId && oldId
          action = reservationForm.getAttribute('action')
          url = action.split('?')
          url = url[0] + '?date_from=' + this.$root.checkin + '&date_to=' + this.$root.checkout + '&guests=' + this.$root.guestNumFilter

          if this.$root.referrer
            url += '&referrer=' + this.$root.referrer

          if this.$root.voucherCodeCampaign
            url += '&code=' + this.$root.voucherCodeCampaign

          window.location.href = url
