







































import VueTinySlider from 'vue-tiny-slider'


export default
  components:
    'tiny-slider': VueTinySlider


  name: 'Gallery'


  props:
    autoheight: default: false
    autoplay: default: false
    autoplaybuttonoutput: default: false
    autoplayhoverpause: default: true
    autoplaytimeout: default: 5000
    autowidth: default: false
    center: default: false
    container: default: 'slider'
    controls: defaults: false
    controlstext: 
      type: Array,
      default: () -> ["<i class='fal fa-angle-left'></i>", "<i class='fal fa-angle-right'></i>"]
    edgepadding: default: 0
    gutter: default: 0
    images: type: Array
    items: default: 1
    loop: default: false
    mode: default: 'carousel'
    mousedrag: default: true
    preventactionwhenrunning: default: false
    nav: default: false
    slideby: default: 1
    speed: default: 400
