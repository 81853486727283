





















import Tab from './tab'
import Tabs from './tabs'

export default
  components: 
    'tab': Tab
    'tabs': Tabs

  data: ->
    icons:
      creditcard: 'fal fa-credit-card fa-lg mr-2'
      paypal: 'fab fa-paypal fa-lg mr-2'
      paypal_creditcard: 'fal fa-credit-card fa-lg mr-2'
      paypal_debit: 'fal fa-money-check-edit fa-lg mr-2'
      sofort: 'fal fa-money-check fa-lg mr-2'

  methods:
    selectedChanged: (selected) ->
      this.$root.prepayType = selected

  name: 'Prepayment'

  props:
    currency: type: String
    loadedtype: default: false
    total: type: Number
    types: type: Array
