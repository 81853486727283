


















export default
  created: ->
    this.tabs = this.$children

  computed:
    selected: ->
      selected = false
      this.tabs.forEach (tab) ->
        selected = tab.name if tab.isActive
      return selected

  data: ->
    tabs: []

  methods:
    selectTab: (selectedTab) ->
      this.tabs.forEach (tab) ->
        tab.isActive = (tab.name == selectedTab.name)

  name: 'Tabs'

  watch:
    selected: (val, oldVal) ->
      this.$emit('selectedChanged', val)
