






import Deal from './deal'


export default
  components:
    'Deal': Deal


  name: 'Deals'


  props:
    deals: type: Array

