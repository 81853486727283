var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.arrangement)?_c('div',{staticClass:"col-12 col-md-6 col-xl-4 arrangement"},[(_vm.arrangement.media.length)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.arrangement.media[0].path}}):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"arrangement__title"},[_vm._v(_vm._s(_vm.arrangement.name))]),_vm._v(" "),_c('div',{staticClass:"arrangement__description",domProps:{"innerHTML":_vm._s(_vm.formattedDescription)}}),_vm._v(" "),(_vm.fromTotalPrice)?_c('div',{staticClass:"arrangement__price"},[_vm._v("\n        "+_vm._s(_vm.$t('booking.from'))+" "+_vm._s(_vm.$root.formatPrice(_vm.fromTotalPrice))+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt15"},[(
                _vm.arrangement.dateranges.length &&
                    !_vm.$root.daterangesContainDates(
                        _vm.arrangement.dateranges,
                        _vm.$root.checkin,
                        _vm.$root.checkout
                    )
            )?_c('small',{staticClass:"alert alert--warning mt10"},[_vm._v("\n            "+_vm._s(_vm.$t(
                    'booking.booking_options_not_bookable_for_this_daterange'
                ))+"\n        ")]):(_vm.$root.durationOfStay < _vm.arrangement.min_stay)?_c('small',{staticClass:"alert alert--warning mt10"},[_vm._v("\n            "+_vm._s(_vm.$t('booking.booking_options_min_stay', {
                    num: _vm.arrangement.min_stay
                }))+"\n        ")]):(_vm.$root.selectedArrangement == _vm.arrangement.id)?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){$event.preventDefault();return _vm.deselectArrangement()}}},[_c('i',{staticClass:"fal fa-check-circle"}),_vm._v("\n            "+_vm._s(_vm.$t('booking.arrangement_selected'))+"\n            "),_c('small',[_vm._v(_vm._s(_vm.$t('booking.click_to_remove')))])]):_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.selectArrangement()}}},[_c('i',{staticClass:"fal fa-plus-circle"}),_vm._v("\n            "+_vm._s(_vm.$t('booking.book_arrangement'))+"\n        ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }