


































export default
  data: ->
    loading: false
    error: false
    voucher:
      amount: 0
      code: this.vouchercode
      description: ''
      type: ''

  methods:
    loadVoucher: (code) ->
      $this = this
      $this.loading = true

      # Get data from the server
      divider = if $this.url.includes('?') then '&' else '?'

      this.$http.get($this.url + divider + 'checkin=' + $this.$root.checkin + '&checkout=' + $this.$root.checkout + '&code=' + $this.voucher.code + '&reduced_price_selected=' + $this.$root.reducedPriceSelected).then((response) ->
        $this.error = false
        $this.loading = false
        if response.data
          $this.voucher.amount = response.data.amount
          $this.voucher.description = response.data.description ? ''
          $this.voucher.type = response.data.type

          $this.$root.voucherCode = $this.voucher.code
          $this.$root.voucherDiscountAmount = response.data.amount

          if response.data.type == '%'
            # Encode %
            $this.$root.voucherDiscountType = '%25'
          else
            $this.$root.voucherDiscountType = response.data.type

          $this.$root.setReservationQuery()
          $this.$root.updateTotals()
        else
          $this.error = true
      ).catch((error) ->
        $this.error = true
        $this.loading = false
      )

  mounted: ->
    # Reset voucher code
    this.$root.voucherCode
    this.$root.voucherDiscountAmount = 0
    this.$root.voucherDiscountType = false

    if this.vouchercode?.length
      this.loadVoucher(this.vouchercode)

  name: 'Voucher'

  props:
    description: type: String
    no_vouchers_for_reduced_rates: type: Boolean
    no_vouchers_for_reduced_rates_alert: type: String
    url: type: String
    vouchercode: type: String
