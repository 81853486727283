
















export default
  name: 'Deal'


  props:
    deal: type: Object
